import config from 'Common/Config/Config'
import { Logger } from 'lib'

const envHosts = {
  local:
    'https://cmp.osano.com/16BZ95S4qp9Kl2gUA/da3676fa-a107-4042-89d6-d35e83e22544/osano.js',
  dev:
    'https://cmp.osano.com/16BZ95S4qp9Kl2gUA/da3676fa-a107-4042-89d6-d35e83e22544/osano.js',
  test:
    'https://cmp.osano.com/16BZ95S4qp9Kl2gUA/da3676fa-a107-4042-89d6-d35e83e22544/osano.js',
  stage:
    'https://cmp.osano.com/16BZ95S4qp9Kl2gUA/da3676fa-a107-4042-89d6-d35e83e22544/osano.js',
  prod:
    'https://cmp.osano.com/16BZ95S4qp9Kl2gUA/da3676fa-a107-4042-89d6-d35e83e22544/osano.js'
}

class Osano {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  setup () {
    this.load()
  }

  load () {
    const url = envHosts[config.env]

    window.require([url], () => this.addListeners())
  }

  addListeners () {
    if (!window.Osano || !window.Osano.cm) {
      this.log.warn('Unable to add event listeners to the Osano cookie manager')
      return
    }

    window.Osano.cm.addEventListener('osano-cm-cookie-blocked', (cookie) =>
      this.log.warn(`Cookie blocked by Osano: ${cookie}`)
    )
    window.Osano.cm.addEventListener('osano-cm-iframe-blocked', (src) =>
      this.log.warn(`iFrame blocked by Osano: ${src}`)
    )
    window.Osano.cm.addEventListener('osano-cm-script-blocked', (src) =>
      this.log.warn(`Script blocked by Osano: ${src}`)
    )
  }
}

export default new Osano()
